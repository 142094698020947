import React from "react";
import './spinner.css';
import SpinnerIcon from '../../images/spinner.png';

const Spinner = () => {
    return (
        <div className="spinner-container">
            <div className="text">waiting transaction</div>
            <div className="icon"><img src={SpinnerIcon} alt="spinner" /></div>
        </div>
    )
};

export default Spinner;
