import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import WaitingLayout from './layouts/WaitingLayout';
import LoginationLayout from './layouts/LoginationLayout';
import Main from './Pages/MainPage/Main';
import Deposit from './Pages/Deposit/Deposit';
import Received from './Components/Received/Received';
import ExchangingConfirmation from './Components/ExchangingConfirmation/ExchangingConfirmation';
import AboutTransaction from './Components/AboutTransaction/AboutTransaction';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />}></Route>
        <Route path='/' element={<MainLayout />}>
          <Route index element={<Main />}></Route>
          <Route path='/deposit' element={<Deposit />}>
            <Route path='waiting-deposit'></Route>
            <Route path='waiting-confirmation' element={<Received />}></Route>
            <Route path='executing-exchange' element={<ExchangingConfirmation />}></Route>
            <Route path='about-transaction' element={<AboutTransaction />}></Route>
          </Route>
        </Route >
        <Route path='/order/:id/' element={<WaitingLayout />}></Route>
        <Route path='/logination/' element={<LoginationLayout />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
