import React from 'react';
import { ReactComponent as BTC } from '../images/icons/BTC.svg';
import { ReactComponent as USDC } from '../images/icons/USDC.svg';
import { ReactComponent as USDT } from '../images/icons/USDT.svg';
import { ReactComponent as BCH } from '../images/icons/BCH.svg';
import { ReactComponent as BNB } from '../images/icons/BNB.svg';
import { ReactComponent as DOGE } from '../images/icons/Doge.svg';
import { ReactComponent as ETH } from '../images/icons/ETH.svg';
import { ReactComponent as LTC } from '../images/icons/LTC.svg';
import { ReactComponent as TRX } from '../images/icons/TRX.svg';
import { ReactComponent as TON } from '../images/icons/TON.svg';
import { ReactComponent as Solana } from '../images/icons/Solana.svg';
import { ReactComponent as Monero } from '../images/icons/Monero.svg';
import { ReactComponent as Dash } from '../images/icons/Dash.svg';
import { ReactComponent as Default } from '../images/icons/hui.svg';


const useCoinSvg = (coinName) => {
    let Comp;
    switch (coinName) {
        case 'USDC':
            Comp = USDC;
            break;
        case 'USDT':
            Comp = USDT;
            break;
        case 'BTC':
            Comp = BTC;
            break;
        case 'BCH':
            Comp = BCH;
            break;
        case 'BNB':
            Comp = BNB;
            break;
        case 'DOGE':
            Comp = DOGE;
            break;
        case 'ETH':
            Comp = ETH;
            break;
        case 'LTC':
            Comp = LTC;
            break;
        case 'TON':
            Comp = TON;
            break;
        case 'TRX':
            Comp = TRX;
            break;
        case 'Solana':
            Comp = Solana;
            break;
        case 'Monero':
            Comp = Monero;
            break;
        case 'Dash':
            Comp = Dash;
            break;
        default:
            Comp = Default
            break;
    }
    return (
        <Comp />
    )
}

export default useCoinSvg