import React from "react";
import './exchange.css';
import { ReactComponent as ShowMoreIcon } from "../../images/icons/arrow-down.svg";
import { ReactComponent as QRIcon } from "../../images/icons/qr.svg";
import { ReactComponent as ExchangeIcon } from "../../images/icons/exchange.svg";
import { ReactComponent as RocketIcon } from "../../images/icons/rocket.svg";
import { ReactComponent as CopyIcon } from "../../images/icons/copy.svg";
import { ReactComponent as SecureIcon } from "../../images/icons/secure.svg";
import { ReactComponent as HelpIcon } from "../../images/icons/help.svg";
import useCoinSvg from "../../CustomHooks/useCoinSvg";
import { useNavigate } from "react-router-dom";

const Exchange = () => {
    const navigate = useNavigate();
    return (
        <div className="exchange-block">
            <div className="first-block">
                <div className="send-receive">
                    <div className="send-receive-text">
                        <div>Send</div>
                        <div className="send-receive-text-price">$75.16</div>
                    </div>
                    <div className="send-receive-input">
                        <input type="number" placeholder="0,45445" />
                        <div className="send-receive-choose-coin">
                            <div className="send-receive-choose-coin-icon">{useCoinSvg()}</div>
                            <div className="send-receive-choose-coin-name">BUSD</div>
                            <div className="send-receive-choose-coin-more"><ShowMoreIcon /></div>
                        </div>
                    </div>
                </div>
                <div className="send-receive-icon"><ExchangeIcon /></div>
                <div className="send-receive">
                    <div className="send-receive-text">
                        <div>Receive (Estimated)</div>
                        <div className="send-receive-text-price">$1.11</div>
                    </div>
                    <div className="send-receive-input">
                        <input type="number" placeholder="0,1205" />
                        <div className="send-receive-choose-coin">
                            <div className="send-receive-choose-coin-icon">{useCoinSvg('USDC')}</div>
                            <div className="send-receive-choose-coin-name">USDC</div>
                            <div className="send-receive-choose-coin-more"><ShowMoreIcon /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="second-block">
                <div className="qr-icon"><QRIcon /></div>
                <input type="text" placeholder="Enter your USDC ERC20 address" />
                <div className="copy-icon"><CopyIcon /></div>
            </div>
            <div className="third-block-text">
                If during the processing time, rates drop more than
                <span style={{ "color": "#c6c6c8" }}> 3% </span>
                you will be refunded the amount of
                <span style={{ "color": "#c79800" }}> 0.45445 BNB </span>
            </div>
            <div className="fourth-block">
                <button className="fourth-block-btn" onClick={() => navigate('/deposit/waiting-deposit')}>
                    <div className="icon"><RocketIcon /></div>
                    <div className="text">Let's Exchange</div>
                </button>
            </div>
            <div className="fifth-block">
                <div className="fifth-block-secure-icon"><SecureIcon /></div>
                <div className="fifth-block-text">Volatility Protection Mode</div>
                <div className="fifth-block-checkbox">
                    <label className="switch" htmlFor="checkbox">
                        <input type="checkbox" id="checkbox" />
                        <div className="slider round"></div>
                    </label>
                </div>
                <div className="fifth-block-help-icon"><HelpIcon /></div>
            </div>
        </div>
    )
};

export default Exchange;
