import React, { useState } from "react";
import './trust.css';
import Star from "./StarSvg";

const Trust = () => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  return (
    <div className="trust-block">
      <div className="trust-first-block">
        <section className="trust-pilot-rate">
          <div className="trust-pilot-rate-content">
            <h2 className="title">
              <Star color={"#00e696"} />
              Trustpilot
            </h2>
            <div className="rate-box">
              <div className="stars">
                {[...Array(5)].map((item, index) => {
                  const currentRating = index + 1;
                  return (
                    <div className="star" key={index} onMouseEnter={() => setHover(currentRating)} onMouseLeave={() => setHover(null)}>
                      <Star color={currentRating <= (hover || rating) ? "#00e696" : "#DCECE0"} />
                    </div>
                  )
                })}
              </div>
              <div className="rate-box-title">
                How was your<br></br> expirience?
              </div>
              <div className="rate-box-hint">
                Rate us and help make our <br></br> service better!
              </div>
            </div>
          </div>
        </section>
        <aside className="trust-box-aside">
          <div className="trust-box-aside-title">
            Trusted by 1000+<br></br> people all around <br></br>the world
          </div>
        </aside>
      </div>
    </div>
  )
};

export default Trust;
