import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
    const mainBackground = document.querySelector('body');
    mainBackground.style.backgroundImage = '';
    return (
        <div className="App">
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
};

export default MainLayout;