// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-container {
    position: fixed;
    width: 100vw;
    height: 87vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
}

.status-container .status-content {
    font-family: 'Poppins';
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/WaitingStatus/StatusContainer/status.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,mCAA2B;YAA3B,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".status-container {\r\n    position: fixed;\r\n    width: 100vw;\r\n    height: 87vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 5;\r\n    backdrop-filter: blur(20px);\r\n}\r\n\r\n.status-container .status-content {\r\n    font-family: 'Poppins';\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
