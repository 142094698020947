import React from "react";

const Star = ({ color }) => {
    return (
        <svg width="38" height="36" viewBox="3 1 30 28" fill={color} preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_103_1717)">
                <path d="M10.9831 26.5L14.11 17.2755L6 11.5508H16.0506L19.2881 2L22.3638 11.5508H31.7458L24.1297 17.0344L27.178 26.5L19.0003 20.7276L10.9831 26.5Z" fill={color} />
                <path d="M10.9831 26.5L14.11 17.2755L6 11.5508H16.0506L19.2881 2L22.3638 11.5508H31.7458L24.1297 17.0344L27.178 26.5L19.0003 20.7276L10.9831 26.5Z" stroke={color} />
            </g>
            <path d="M24.83 19.4828L21.2285 20.2539L24.3544 18.0053L24.83 19.4828Z" fill="#5D5D63" stroke="#5D5D63" />
            <defs>
                <filter id="filter0_d_103_1717" x="0.424561" y="0.406647" width="36.8713" height="35.4113" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_103_1717" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_103_1717" result="shape" />
                </filter>
            </defs>
        </svg>

    )
};

export default Star;
