import React, { useState } from "react";
import './recovery.css';
import { ReactComponent as HideIcon } from "../../images/icons/hide-icon.svg";
import { ReactComponent as ShowIcon } from "../../images/icons/show-icon.svg";
import { ReactComponent as HintIcon } from "../../images/icons/hint-icon.svg";
import { useDispatch } from "react-redux";
import { cosmoAction } from "../../redux/slices/cosmoSlice";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const Recovery = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const [showCodeField, setShowCodeField] = useState(false);
    const dispatch = useDispatch();
    return (
        <div className="recovery-container">
            <form className="recovery-content">
                <div className="login-or-register">
                    <div className="main-text">Recovery</div>
                    <div className="or">or</div>
                    <div className="secondary-text" onClick={() => dispatch(cosmoAction.setAuthstage('login'))}>login</div>
                </div>
                <div className="hint">Follow the instructions from the Telegram bot Link @cosmomoll</div>
                <label className="field" htmlFor="userId">
                    <div className="hint-box">
                        <div className="text">User ID</div>
                        <div className="icon"><HintIcon /></div>
                    </div>
                    <div className="input-box">
                        <input id="userId" name="userId" type="text" placeholder="Enter your User ID.. " autoComplete="off" />
                    </div>
                </label>
                <div className="field">
                    <div className="hint-box">
                        <div className="text">API Key</div>
                        <div className="icon"><HintIcon /></div>
                    </div>
                    <label className="input-box" htmlFor="APIKey">
                        <input id="APIKey" type={showPassword ? "text" : "password"} placeholder="Enter your API Key.. " />
                        <div className="hide-icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <ShowIcon /> : <HideIcon />}
                        </div>
                    </label>
                </div>
                <SwitchTransition mode={'out-in'}>
                    <CSSTransition in={showCodeField} timeout={500} key={showCodeField} classNames="transition-swap">
                        {showCodeField ?
                            <div className="field">
                                <div className="hint-box">
                                    <div className="text">Code</div>
                                </div>
                                <label className="input-box" htmlFor="code">
                                    <input id="code" type={showCode ? "text" : "password"} placeholder="Enter code from telegram.. " />
                                    <div className="hide-icon" onClick={() => setShowCode(!showCode)}>
                                        {showCode ? <ShowIcon /> : <HideIcon />}
                                    </div>
                                </label>
                            </div>
                            :
                            <div className="login-btn" onClick={() => setShowCodeField(!showCodeField)}>Sent message</div>}
                    </CSSTransition>
                </SwitchTransition>
            </form>
        </div>
    )
};

export default Recovery;
