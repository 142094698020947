import React from "react";
import './loginationPage.css';
import { useSelector } from "react-redux";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Logination from "../../Components/Logination/Logination";
import Registration from "../../Components/Registration/Registration";
import Recovery from "../../Components/Recovery/Recovery";



const LoginationPage = () => {
    const { authStage } = useSelector(state => state.cosmoReducer);
    return (
        <div className="logination-page">
            <Header waiting={true} login={true} />
            {authStage === 'login' ? <Logination /> : null}
            {authStage === 'register' ? <Registration /> : null}
            {authStage === 'recovery' ? <Recovery /> : null}
            <Footer status={'waiting'} />
        </div>
    )
};

export default LoginationPage;
