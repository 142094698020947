import React from "react";
import './waiting.css';
import Spinner from "../../Components/Spinner/Spinner";
import WaitingAddress from "../../Components/WaitingAddress/WaitingAddress";
import Background from '../../images/BG.png';

const Waiting = () => {
    const mainBackground = document.querySelector('body');
    mainBackground.style.backgroundImage = `url(${Background})`;
    return (
        <div className="waiting-container">
            <WaitingAddress />
            <Spinner />
        </div>
    )
};

export default Waiting;
