import React, { useEffect } from "react";
import './progress.css';
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DepositIcon } from "../../images/icons/deposit-icon.svg";
import { ReactComponent as NextIcon } from "../../images/icons/arrow-right.svg";
import { ReactComponent as DoneIcon } from "../../images/icons/done-icon.svg";
import { ReactComponent as WaitingConfirmationIcon } from "../../images/icons/Clock.svg";
import { ReactComponent as ConfirmationIcon } from "../../images/icons/confirmation.svg";
import { ReactComponent as ExchangeIcon } from "../../images/icons/currency-exchange.svg";
import { useDispatch, useSelector } from "react-redux";
import { cosmoAction } from "../../redux/slices/cosmoSlice";



const Progress = () => {
    const { depositStage } = useSelector(state => state.cosmoReducer);
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // useEffect(() => {

    // }, [stage_name])
    return (
        <div className="progress-block">
            <div className={`stage-box ${(depositStage === 'stage 2' || depositStage === 'stage 3' || depositStage === 'stage 4') ? 'accepted'
                : location === '/deposit/waiting-deposit' ? 'active' : null}`}>
                <div className="number">Stage 1</div>
                <div className="icon" onClick={() => {
                    dispatch(cosmoAction.setStage('stage 1'))
                    navigate('/deposit/waiting-deposit')
                }}><DepositIcon /></div>
                <div className="title">Waiting for<br></br> Deposit</div>
            </div>
            <div className={`icon-next ${(depositStage === 'stage 1' || depositStage === 'stage 2' || depositStage === 'stage 3' || depositStage === 'stage 4') ? 'active' : null}`}>
                {(depositStage === 'stage 2' || depositStage === 'stage 3' || depositStage === 'stage 4') ? <DoneIcon /> : <NextIcon />}
            </div>
            <div className={`stage-box ${(depositStage === 'stage 3' || depositStage === 'stage 4') ? 'accepted'
                : location === '/deposit/waiting-confirmation' ? 'active' : null}`}>
                <div className="number">Stage 2</div>
                <div className="icon" onClick={() => {
                    dispatch(cosmoAction.setStage('stage 1'))
                    dispatch(cosmoAction.setStage('stage 2'))
                    navigate('/deposit/waiting-confirmation')
                }}><WaitingConfirmationIcon /></div>
                <div className="title">Waiting for<br></br> Confirmations</div>
            </div>
            <div className={`icon-next ${(depositStage === 'stage 2' || depositStage === 'stage 3' || depositStage === 'stage 4') ? 'active' : null}`}>
                {(depositStage === 'stage 3' || depositStage === 'stage 4') ? <DoneIcon /> : <NextIcon />}
            </div>
            <div className={`stage-box ${depositStage === 'stage 4' ? 'accepted'
                : location === '/deposit/executing-exchange' ? 'active' : null}`}>
                <div className="number">Stage 3</div>
                <div className="icon" onClick={() => {
                    dispatch(cosmoAction.setStage('stage 3'))
                    navigate('/deposit/executing-exchange')
                }}><ExchangeIcon /></div>
                <div className="title">Executing<br></br> Exchange</div>
            </div>
            <div className={`icon-next ${(depositStage === 'stage 3' || depositStage === 'stage 4') ? 'active' : null}`}>
                {depositStage === 'stage 4' ? <DoneIcon /> : <NextIcon />}
            </div>
            <div className={`stage-box ${depositStage === 'stage 4' ? 'accepted' : null}`}>
                <div className="number">Stage 4</div>
                <div className="icon" onClick={() => {
                    dispatch(cosmoAction.setStage('stage 4'))
                    navigate('/deposit/about-transaction')
                }}><ConfirmationIcon /></div>
                <div className="title">Exchange<br></br> Completed</div>
            </div>
        </div >
    )
};

export default Progress;
