import React from "react";
import { ReactComponent as OppositeArrowsIcon } from "../../images/icons/2opositearrows.svg";

const Item = ({ time, sum1, coinName1, shortCoinName1, coinColor1, coinImg1, coinImg2, coinName2, shortCoinName2, coinColor2, sum2, reserve }) => {
    return (
        <li className="item">
            <div className="time">{time} ago</div>
            <div className="sum1">{sum1}</div>
            <div className="coin-name-1" style={{ "color": coinColor1 }}>{coinName1}<br></br><b>{shortCoinName1}</b></div>
            <div>{coinImg1}</div>
            <OppositeArrowsIcon />
            <div>{coinImg2}</div>
            <div className="coin-name-2" style={{ "color": coinColor2 }}>{coinName2}<br></br><b>{shortCoinName2}</b></div>
            <div className="sum2">{sum2}</div>
            <div className="reserve">Reserve: <b>{reserve}</b></div>
        </li>
    )
};

export default Item;
