import React from "react";
import './header.css';
import Logo from '../../images/logo.png';
import USA from '../../images/USA.png';
import { useNavigate } from "react-router-dom";

const Header = ({ waiting, login = false }) => {
  const navigate = useNavigate();
  return (
    <header className="main-header">
      <div className="logo" onClick={() => navigate('/')}>
        <img src={Logo} alt="logo" className="logo-img" />
        <h1 className="logo-text">COSMOMOLL</h1>
      </div>
      <nav className="navigation">
        {login ? null
          :
          waiting ?
            <div onClick={() => {
              navigate('/logination/')
              window.location.reload();
            }}>API</div>
            :
            <div>Our service</div>
        }
        {login ? null : waiting ? null : <div>Recent launches</div>}
        {login ? null : <div>Support</div>}
        <div className="language">
          <span className="text">Language  EN</span>
          <img src={USA} alt="flag" />
        </div>
        <div className="language-600px">
          <span className="text">EN</span>
          <img src={USA} alt="flag" />
        </div>
      </nav>
    </header>
  )
};

export default Header;
