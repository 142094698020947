import React from "react";
import './info.css';
import useCoinSvg from "../../CustomHooks/useCoinSvg";
import { ReactComponent as ClockIcon } from "../../images/icons/Clock.svg"


const Info = () => {
    return (
        <article className="info">
            <div className="you-send">
                <div className="title">YOU SEND</div>
                <div className="amount-of-coin">
                    <div className="amount">8,4544</div>
                    <div className="coin-logo">{useCoinSvg()}</div>
                    <div className="coin-name">BUSD</div>
                </div>
                <div className="address">0x807e7f3d5aa96b402ad4cethehehd730d7375473</div>
            </div>
            <div className="clock-icon"><ClockIcon /></div>
            <div className="you-receive">
                <div className="title">YOU RECEIVE</div>
                <div className="amount-of-coin">
                    <div className="amount">77,4247</div>
                    <div className="coin-logo">{useCoinSvg('USDC')}</div>
                    <div className="coin-name">USDC</div>
                </div>
                <div className="address">0x807e7f3d5aqerqerqerad4c9d64e15d730d7375473</div>
            </div>
        </article>
    )
};

export default Info;
