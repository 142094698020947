// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat {
    position: fixed;
    bottom: 5%;
    right: 2%;
    display: flex;
    justify-content: flex-end;
    z-index: 10;
}

.chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
}

.chat button {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    background: #25222f;
    border-radius: 100%;
    padding: 0.9rem;
}

.chat .text {
    display: flex;
    text-align: center;
}

@media (min-width: 1600px) {
    .chat button svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    .chat button {
        padding: 1.5rem;
    }

    .chat .text {
        font-size: 1.5em;
    }
}

@media (max-width: 600px) {
    .chat {
        bottom: 5%;
        right: 2%;
    }

    .chat-container {
        align-items: center;
        font-weight: 300;
    }

    .chat button svg {
        width: 1.125rem;
        height: 1.125rem;
    }

    .chat button {
        padding: 0.6rem;
    }

    .chat .text {
        font-size: 0.75em;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Chat/chat.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,SAAS;IACT,aAAa;IACb,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,UAAU;IACV,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;QACb,cAAc;IAClB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,SAAS;IACb;;IAEA;QACI,mBAAmB;QACnB,gBAAgB;IACpB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".chat {\r\n    position: fixed;\r\n    bottom: 5%;\r\n    right: 2%;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    z-index: 10;\r\n}\r\n\r\n.chat-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    font-weight: 300;\r\n}\r\n\r\n.chat button {\r\n    width: fit-content;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border: 0;\r\n    outline: 0;\r\n    background: #25222f;\r\n    border-radius: 100%;\r\n    padding: 0.9rem;\r\n}\r\n\r\n.chat .text {\r\n    display: flex;\r\n    text-align: center;\r\n}\r\n\r\n@media (min-width: 1600px) {\r\n    .chat button svg {\r\n        width: 2.5rem;\r\n        height: 2.5rem;\r\n    }\r\n\r\n    .chat button {\r\n        padding: 1.5rem;\r\n    }\r\n\r\n    .chat .text {\r\n        font-size: 1.5em;\r\n    }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n    .chat {\r\n        bottom: 5%;\r\n        right: 2%;\r\n    }\r\n\r\n    .chat-container {\r\n        align-items: center;\r\n        font-weight: 300;\r\n    }\r\n\r\n    .chat button svg {\r\n        width: 1.125rem;\r\n        height: 1.125rem;\r\n    }\r\n\r\n    .chat button {\r\n        padding: 0.6rem;\r\n    }\r\n\r\n    .chat .text {\r\n        font-size: 0.75em;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
