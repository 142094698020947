import React from "react";
import './error.css';
import CircleImg from '../../../../images/error-circle.png';
import { ReactComponent as Rocket } from '../../../../images/icons/error-rocket.svg';
import { ReactComponent as CopyIcon } from '../../../../images/icons/copy.svg';

const Error = ({ text }) => {
    return (
        <div className="error-container">
            <div className="icon-box">
                <div className="circle"><img src={CircleImg} alt="circle" /></div>
                <div className="rocket"><Rocket /></div>
            </div>
            <div className="text-box">
                {text === 'Not found.' ?
                    <div className="text">Not found</div>
                    :
                    <div className="text">Something went wrong</div>
                }
                <div className="hint">Please try again</div>
            </div>
            <div className="btn-box">
                <div className="copy-btn"><CopyIcon /></div>
                <div className="sup-btn">Contact support</div>
                <div className="try-again-btn">Try again</div>
            </div>
        </div>
    )
};

export default Error;
