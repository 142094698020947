// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 400;
    font-size: 0.875em;
}

@keyframes myAnim {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-360deg);
    }
}

.spinner-container .icon {
    display: flex;
}

.spinner-container .icon img {
    width: 1.5rem;
    height: 1.5rem;
    animation: myAnim 0.5s linear 0s infinite normal forwards;
}`, "",{"version":3,"sources":["webpack://./src/Components/Spinner/spinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,oBAAoB;IACxB;;IAEA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,yDAAyD;AAC7D","sourcesContent":[".spinner-container {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 1rem;\r\n    font-weight: 400;\r\n    font-size: 0.875em;\r\n}\r\n\r\n@keyframes myAnim {\r\n    0% {\r\n        transform: rotate(0);\r\n    }\r\n\r\n    100% {\r\n        transform: rotate(-360deg);\r\n    }\r\n}\r\n\r\n.spinner-container .icon {\r\n    display: flex;\r\n}\r\n\r\n.spinner-container .icon img {\r\n    width: 1.5rem;\r\n    height: 1.5rem;\r\n    animation: myAnim 0.5s linear 0s infinite normal forwards;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
