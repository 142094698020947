import React from "react";
import './status.css';
import Loading from "../StatusComponents/Loading/Loading";
import Success from "../StatusComponents/Success/Success";
import Error from "../StatusComponents/Error/Error";

const Status = ({ status, text }) => {
    return (
        <div className="status-container">
            <div className="status-content">
                {status === 'loading' ? <Loading /> : null}
                {status === 'success' ? <Success /> : null}
                {status === 'canceled' ? <Error text={text}/> : null}
            </div>
        </div>
    )
};

export default Status;
