import React from "react";
import './aboutTransaction.css';
import { ReactComponent as ClockResetIcon } from "../../images/icons/Clock-reset.svg";
import { ReactComponent as BarsIcon } from "../../images/icons/2bars.svg";
import { ReactComponent as CopyIcon } from "../../images/icons/copy.svg";

const AboutTransaction = () => {
    return (
        <div className="about-transaction">
            <div className="about-operation">
                <h2>About received transaction</h2>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        TxID
                    </div>
                    <div className="status-value" style={{ "width": "70%" }}>
                        <div className="address-text">0x807e7f3d5aqe15d7erre15d7d4c9d64e15d730d73754d7d4c9d64e15d73073</div>
                        <CopyIcon />
                    </div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        Time of Receipt
                    </div>
                    <div className="status-value">05.02.2024 20:06</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Time of Inclusion in Block
                    </div>
                    <div className="status-value">05.02.2024 20:06</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Confirmations
                    </div>
                    <div className="status-value">4 of 4</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        Amount
                    </div>
                    <div className="status-value">8,4544</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Fee
                    </div>
                    <div className="status-value">0,0016</div>
                </div>
            </div>
            <div className="about-operation">
                <h2>About received transaction</h2>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        TxID
                    </div>
                    <div className="status-value" style={{ "width": "70%" }}>
                        <div className="address-text">0x807e7f3d5aqe15d7erre15d7d4c9d64e15d730d73754d7d4c9d64e15d73073</div>
                        <CopyIcon />
                    </div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        Time of Receipt
                    </div>
                    <div className="status-value">05.02.2024 20:06</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Time of Inclusion in Block
                    </div>
                    <div className="status-value">05.02.2024 20:06</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Confirmations
                    </div>
                    <div className="status-value">4 of 4</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        Amount
                    </div>
                    <div className="status-value">8,4544</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Fee
                    </div>
                    <div className="status-value">0,0016</div>
                </div>
            </div>
        </div>
    )
};

export default AboutTransaction;
