import React from "react";
import './chat.css';
import { ReactComponent as ChatIcon } from "../../images/icons/Chat.svg";

const Chat = () => {
    return (
        <div className="chat">
            <div className="chat-container">
                <button><ChatIcon /></button>
                <div className="text">Support<br></br>Chat</div>
            </div>
        </div>
    )
};

export default Chat;
