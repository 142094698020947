// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logination-page {
    display: grid;
    height: -webkit-fill-available;
}

.logination-page .main-header {
    align-self: flex-start;
}

.logination-page .main-footer {
    align-self: flex-end;
    padding-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LoginationPage/loginationPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;AACxB","sourcesContent":[".logination-page {\r\n    display: grid;\r\n    height: -webkit-fill-available;\r\n}\r\n\r\n.logination-page .main-header {\r\n    align-self: flex-start;\r\n}\r\n\r\n.logination-page .main-footer {\r\n    align-self: flex-end;\r\n    padding-bottom: 2rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
