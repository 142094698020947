import React from "react";
import './main.css';
import Axis from '../../images/axis.png';
import Planet1 from '../../images/planet1.png';
import Planet2 from '../../images/planet2.png';
import BigPlanet from "../../images/BigPlanet.png";
import Exchange from "../../Components/Exchange/Exchange";
import Trust from "../../Components/Trust/Trust";
import Options from "../../Components/Options/Options";
import RecentLaunches from "../../Components/RecentLaunches/RecentLaunches";
import Chat from "../../Components/Chat/Chat";
import { ReactComponent as Nest } from "../../images/icons/planet-nest.svg";
import { ReactComponent as Planet } from "../../images/icons/planet1.svg";

const Main = () => {
    return (
        <main className="main-page">
            <img src={Axis} alt="axis" className="main-backround-axis" />
            {/* <div className="main-backround-planet1">
                <div className="nest"><Nest /></div>
                <div className="planet"><Planet /></div>
            </div> */}
            <img src={Planet1} alt="planet 1" className="main-backround-planet1" />
            <img src={Planet2} alt="planet 2" className="main-backround-planet2" />
            <h2 className="main-title">Exchange fast and secure</h2>
            <h3 className="main-subtitle">Select a cryptocurrency and enter the omount.</h3>
            <Exchange />
            <Chat />
            <Trust />
            <Options />
            <img src={BigPlanet} alt="big planet" className="main-backround-big-planet" />
            <RecentLaunches />
        </main>
    )
};

export default Main;
