import React from "react";
import './loading.css';
import SpinnerIcon from '../../../../images/spinner.png';

const Loading = () => {
    return (
        <div className="loading">
            <img src={SpinnerIcon} alt="spinner" />
            <div className="text">We received your payment</div>
        </div>
    )
};

export default Loading;
