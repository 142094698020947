import React from "react";
import './recentLaunches.css';
import Item from "./Item";
import { ReactComponent as Bitcoin } from '../../images/icons/BTC.svg';
import { ReactComponent as USDT } from '../../images/icons/USDT.svg';

const RecentLaunches = () => {
    return (
        <div className="recent-launches-block">
            <div className="recent-launches-content">
                <div className="backround-text-shadow">RECENT LAUNCHES</div>
                <h2 className="title">Recent launches</h2>
                <ul className="list">
                    <Item
                        time={"1d 3h"}
                        sum1={0.001}
                        coinName1={'Bitcoin'}
                        shortCoinName1={'BTC'}
                        coinColor1={'#E09329'}
                        coinImg1={<Bitcoin />}
                        coinImg2={<USDT />}
                        coinColor2={'#8CD7C3'}
                        coinName2={'Tether'}
                        shortCoinName2={'USDT'}
                        sum2={5.001}
                        reserve={'210 000 USDT'}
                    />
                    <Item
                        time={"1d 3h"}
                        sum1={0.001}
                        coinName1={'Bitcoin'}
                        shortCoinName1={'BTC'}
                        coinColor1={'#E09329'}
                        coinImg1={<Bitcoin />}
                        coinImg2={<USDT />}
                        coinColor2={'#8CD7C3'}
                        coinName2={'Tether'}
                        shortCoinName2={'USDT'}
                        sum2={5.001}
                        reserve={'210 000 USDT'}
                    />
                    <Item
                        time={"1d 3h"}
                        sum1={0.001}
                        coinName1={'Bitcoin'}
                        shortCoinName1={'BTC'}
                        coinColor1={'#E09329'}
                        coinImg1={<Bitcoin />}
                        coinImg2={<USDT />}
                        coinColor2={'#8CD7C3'}
                        coinName2={'Tether'}
                        shortCoinName2={'USDT'}
                        sum2={5.001}
                        reserve={'210 000 USDT'}
                    />
                    <Item
                        time={"1d 3h"}
                        sum1={0.001}
                        coinName1={'Bitcoin'}
                        shortCoinName1={'BTC'}
                        coinColor1={'#E09329'}
                        coinImg1={<Bitcoin />}
                        coinImg2={<USDT />}
                        coinColor2={'#8CD7C3'}
                        coinName2={'Tether'}
                        shortCoinName2={'USDT'}
                        sum2={5.001}
                        reserve={'210 000 USDT'}
                    />
                </ul>
            </div>
        </div>
    )
};

export default RecentLaunches;
