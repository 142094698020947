// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-container .status-content .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 0.875em;
    gap: 1rem;
}


.status-container .status-content .success .icon-box {
    position: relative;
}

@keyframes myAnim {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-360deg);
    }
}

/* .status-container .status-content .success .icon-box .circle {
    animation: myAnim 0.5s linear 0s infinite reverse forwards;
} */
.status-container .status-content .success .icon-box .circle img {
    width: 5.688rem;
    height: 5.688rem;
}

.status-container .status-content .success .icon-box .rocket {
    display: flex;
    position: absolute;
    top: 21%;
    left: 26%;
}

.status-container .status-content .success .hint {
    margin-top: 1rem;
    text-shadow: 0px 0px 4px #0000000D;
    padding: 1rem 5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #15151F 0%, rgba(59, 60, 78, 0) 100%);
    background: linear-gradient(270.49deg, #54E451 4.91%, #33CE84 96.72%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}`, "",{"version":3,"sources":["webpack://./src/Components/WaitingStatus/StatusComponents/Success/success.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,oBAAoB;IACxB;;IAEA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;;GAEG;AACH;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,kCAAkC;IAClC,kBAAkB;IAClB,mBAAmB;IACnB,qCAAqC;IACrC,iBAAiB;IACjB,kFAAkF;IAClF,oIAAoI;AACxI","sourcesContent":[".status-container .status-content .success {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    font-weight: 600;\r\n    font-size: 0.875em;\r\n    gap: 1rem;\r\n}\r\n\r\n\r\n.status-container .status-content .success .icon-box {\r\n    position: relative;\r\n}\r\n\r\n@keyframes myAnim {\r\n    0% {\r\n        transform: rotate(0);\r\n    }\r\n\r\n    100% {\r\n        transform: rotate(-360deg);\r\n    }\r\n}\r\n\r\n/* .status-container .status-content .success .icon-box .circle {\r\n    animation: myAnim 0.5s linear 0s infinite reverse forwards;\r\n} */\r\n.status-container .status-content .success .icon-box .circle img {\r\n    width: 5.688rem;\r\n    height: 5.688rem;\r\n}\r\n\r\n.status-container .status-content .success .icon-box .rocket {\r\n    display: flex;\r\n    position: absolute;\r\n    top: 21%;\r\n    left: 26%;\r\n}\r\n\r\n.status-container .status-content .success .hint {\r\n    margin-top: 1rem;\r\n    text-shadow: 0px 0px 4px #0000000D;\r\n    padding: 1rem 5rem;\r\n    border-radius: 10px;\r\n    box-shadow: 0px 4px 4px 0px #00000040;\r\n    border: 1px solid;\r\n    border-image-source: linear-gradient(180deg, #15151F 0%, rgba(59, 60, 78, 0) 100%);\r\n    background: linear-gradient(270.49deg, #54E451 4.91%, #33CE84 96.72%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
