import React, { useState } from "react";
import './logination.css';
import { ReactComponent as HideIcon } from "../../images/icons/hide-icon.svg";
import { ReactComponent as ShowIcon } from "../../images/icons/show-icon.svg";
import { useDispatch } from "react-redux";
import { cosmoAction } from "../../redux/slices/cosmoSlice";

const Logination = () => {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    return (
        <div className="logination-container">
            <form className="logination-content">
                <div className="login-or-register">
                    <div className="main-text">Login</div>
                    <div className="or">or</div>
                    <div className="secondary-text" onClick={() => dispatch(cosmoAction.setAuthstage('register'))}>Sign up</div>
                </div>
                <label className="field" htmlFor="email">
                    <div className="hint">Enter your Email</div>
                    <div className="input-box">
                        <input id="email" name="email" type="email" placeholder="example@mail.com" autoComplete="off" />
                    </div>
                </label>
                <div className="field">
                    <div className="hint">Enter your Password</div>
                    <label className="input-box" htmlFor="password">
                        <input id="password" type={showPassword ? "text" : "password"} placeholder="********" />
                        <div className="hide-icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <ShowIcon /> : <HideIcon />}
                        </div>
                    </label>
                </div>
                <div className="recovery-n-forget">
                    <div className="recovery" onClick={() => dispatch(cosmoAction.setAuthstage('recovery'))}>Recovery via Telegram API</div>
                    <div className="forget">Forget password</div>
                </div>
                <div className="login-btn">Login</div>
            </form>
        </div>
    )
};

export default Logination;
