import React, { useEffect, useState } from "react";
import Waiting from "../Pages/Waiting/Waiting";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Status from "../Components/WaitingStatus/StatusContainer/Status";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cosmoAction } from "../redux/slices/cosmoSlice";

const WaitingLayout = () => {
    const { error, status } = useSelector(state => state.cosmoReducer);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [order, setOrder] = useState(id);
    useEffect(() => {
        dispatch(cosmoAction.getOrder(order))
        setInterval(() => {
            dispatch(cosmoAction.getOrder(order))
        }, 5000)
    }, [dispatch, order])
    return (
        <div style={{ 'height': '-webkit-fill-available' }}>
            <Header waiting={true} />
            {error === 'Not found.' ?
                <Status status={'canceled'} text={'Not found.'} />
                :
                error ?
                    <Status status={'canceled'} />
                    :
                    status === 'loading' ?
                        <Status status={'loading'} />
                        :
                        status === 'success' ?
                            <Status status={'success'} />
                            :
                            status === 'canceled' ?
                                <Status status={'canceled'} />
                                : null
            }
            <Waiting />
            <Footer status={'waiting'} />
        </div>
    )
};

export default WaitingLayout;
