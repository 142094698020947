import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { cosmoReducer } from "./slices/cosmoSlice";


const rootReducer = combineReducers({
    cosmoReducer
});

const setupStore = () => configureStore({
    reducer: rootReducer
})

export {
    setupStore
}