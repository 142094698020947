import React from "react";
import './options.css';
import { ReactComponent as ArrowLeftIcon } from "../../images/icons/chervon-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/icons/chervon-right.svg";
import { ReactComponent as ArrowIcon } from "../../images/icons/↗.svg";
import { ReactComponent as ArrowTopIcon } from "../../images/icons/arrow-top.svg";

const Options = () => {
    return (
        <div className="options-block">
            <div className="options-block-content">
                <div className="go-to-the-top-btn-container">
                    <div className="go-to-the-top-btn">
                        <div className="text">GO TO THE TOP</div>
                        <button><ArrowTopIcon /></button>
                    </div>
                </div>
                <div className="slider-arrows-container">
                    <div className="arrow"><ArrowLeftIcon /></div>
                    <div className="arrow"><ArrowRightIcon /></div>
                </div>
                <div className="options-container">
                    <div className="option-box">
                        <div className="type">FAST</div>
                        <div className="title">Lightning-Fast
                            <ArrowIcon /><br></br>
                            Crypto Exchanges</div>
                        <div className="text">
                            Our platform ensures rapid <br></br> exchanges,
                            allowing you to seize opportunities in the dynamic <br></br> world of digital assets.
                        </div>
                    </div>
                    <div className="option-box">
                        <div className="type">TRANSPARENT</div>
                        <div className="title">Track Your Exchange<br></br> Journey</div>
                        <div className="text">
                            At each step, you have full visibility,
                            ensuring you're always in the know <br></br> about your transaction progress.
                        </div>
                    </div>
                    <div className="option-box">
                        <div className="type">SECURE</div>
                        <div className="title">Secure Your Crypto<br></br> Assets</div>
                        <div className="text">
                            Security is our top priority. Benefit <br></br> from our robust security <br></br> measures,
                            ensuring your assets <br></br> are protected throughout every <br></br>  transaction.
                        </div>
                    </div>
                    <div className="option-box">
                        <div className="type">CONFIDENTAL</div>
                        <div className="title">Your Privacy <br></br> Matters</div>
                        <div className="text">
                            We prioritizes the confidentiality of <br></br> your crypto activities.
                            Enjoy <br></br> discreet and confidential <br></br> transactions.
                            <br></br>
                            Your secrets are safe with us.
                        </div>
                    </div>
                </div>
            </div>
            <div className="adaptive-block-905px">
                <input defaultChecked type="radio" name="respond" id="desktop"></input>
                <article id="slider">
                    <input defaultChecked type="radio" name="slider" id="switch1"></input>
                    <input type="radio" name="slider" id="switch2"></input>
                    <input type="radio" name="slider" id="switch3"></input>
                    <input type="radio" name="slider" id="switch4"></input>
                    <div id="controls">
                        <label htmlFor="switch1"></label>
                        <label htmlFor="switch2"></label>
                        <label htmlFor="switch3"></label>
                        <label htmlFor="switch4"></label>
                    </div>
                    <div id="slides">
                        <div id="overflow">
                            <div className="option-container">
                                <article className="option-box">
                                    <div className="type">FAST</div>
                                    <div className="title">Lightning-Fast
                                        <ArrowIcon /><br></br>
                                        Crypto Exchanges</div>
                                    <div className="text">
                                        Our platform ensures rapid exchanges,
                                        allowing you to seize opportunities in the dynamic world of digital assets.
                                    </div>
                                </article>
                                <article className="option-box">
                                    <div className="type">TRANSPARENT</div>
                                    <div className="title">Track Your <br></br>Exchange Journey</div>
                                    <div className="text">
                                        At each step, you have full visibility,
                                        ensuring you're always in the know about your transaction progress.
                                    </div>
                                </article>
                                <article className="option-box">
                                    <div className="type">SECURE</div>
                                    <div className="title">Secure Your Crypto<br></br> Assets</div>
                                    <div className="text">
                                        Security is our top priority. Benefit from our robust security measures,
                                        ensuring your assets are protected throughout every transaction.
                                    </div>
                                </article>
                                <article className="option-box">
                                    <div className="type">CONFIDENTAL</div>
                                    <div className="title">Your Privacy <br></br> Matters</div>
                                    <div className="text">
                                        We prioritizes the confidentiality of your crypto activities.
                                        Enjoy discreet and confidential transactions.
                                        <br></br>
                                        Your secrets are safe with us.
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                </article>
            </div>
        </div>
    )
};

export default Options;
