import React from "react";
import LoginationPage from "../Pages/LoginationPage/LoginationPage";

const LoginationLayout = () => {
  return (
    <div style={{ 'height': '-webkit-fill-available' }}>
      <LoginationPage />
    </div>
  )
};

export default LoginationLayout;
