import React from "react";
import './footer.css';
import USA from '../../images/USA.png';

const Footer = ({ status }) => {
    return (
        <footer className={`main-footer ${status}`}>
            <section>
                <h2>© Cosmomoll 2024. All rights reserved.</h2>
                {status === 'waiting' ? null : <h3>Recent launches</h3>}
                {status === 'waiting' ? null : <h3>FAQ</h3>}
            </section>
            {status === 'waiting' ? null :
                <aside>
                    <span>Language: EN</span>
                    <img src={USA} alt="flag" />
                </aside>
            }
        </footer>
    )
};

export default Footer;
