import React from "react";
import './received.css';
import { ReactComponent as ClockIcon } from "../../images/icons/Clock.svg";

const Received = () => {
    return (
        <div className="received">
            <div className="text-box">
                <div className="title">Thank You</div>
                <div className="sub-title">We received your Deposit, Waiting for Network Confirmations</div>
            </div>
            <div className="progress-counter">
                <div className="icon"><ClockIcon /></div>
                <div className="counter-text">1 of 4</div>
            </div>
        </div>
    )
};

export default Received;
