import React from "react";
import './waitingAddress.css';
import QR from '../../images/qr.png';
import { useSelector } from "react-redux";
import { ReactComponent as CopyIcon } from '../../images/icons/copy.svg';
import useCoinSvg from "../../CustomHooks/useCoinSvg";

const WaitingAddress = () => {
    const { currency, paymentsAdress, amoutBase, amountUSDT } = useSelector(state => state.cosmoReducer);
    return (
        <div className="waiting-address-container">
            <div className="address-input">
                <div className="title">Address</div>
                <div className="input-box">
                    <input type="text" defaultValue={paymentsAdress} readOnly />
                    <div className="copy-icon"><CopyIcon /></div>
                </div>
            </div>
            <div className="text">Send <b>{amoutBase}</b> <b>{currency.name}({currency.network.name.toUpperCase()})</b> to this address, it will automatically credited to your account.</div>
            <div className="qr-box">
                <img className="qr-img" src={QR} alt="qr" />
                <div className="other-info">
                    <div className="info-box">
                        <div className="hint-box">
                            <div className="hint">Select payment method</div>
                            <div className="price">${amountUSDT}</div>
                        </div>
                        <div className="input-box">
                            <div className="coin-box">
                                <div className="coin-icon">{useCoinSvg(currency.name)}</div>
                                <div className="coin-name">{currency.name} ({currency.network.name.toUpperCase()})</div>
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="hint-title">Please provide your email address for payment.</div>
                        <div className="input-box">
                            <input type="text" placeholder="example@mail.com" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WaitingAddress;
