import React from "react";
import './success.css';
import { ReactComponent as Rocket } from '../../../../images/icons/success-rocket.svg';
import CircleImg from '../../../../images/success-circle.png';

const Success = () => {
    return (
        <div className="success">
            <div className="icon-box">
                <div className="circle"><img src={CircleImg} alt="circle" /></div>
                <div className="rocket"><Rocket /></div>
            </div>
            <div className="text">Thank You</div>
            <div className="hint">You will be automatically redirected</div>
        </div>
    )
};

export default Success;
