// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposit-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 24%;
    overflow: hidden;
}

.deposit-backround-big-planet {
    position: absolute;
    z-index: 1;
    top: 68%;
    width: 100vw;
}

@media (max-width: 1230px) {
    .deposit-backround-big-planet {
        top: 73%;
    }
}

@media (max-width: 1000px) {
    .deposit-backround-big-planet {
        top: 77%;
    }
}

@media (max-width: 905px) {
    .deposit-backround-big-planet {
        top: 83%;
    }
}

@media (max-width: 780px) {
    .deposit-backround-big-planet {
        top: 87%;
    }
}

@media (max-width: 600px) {
    .deposit-block {
        margin-top: 0;
    }

    .deposit-backround-big-planet {
        top: 91%;
    }
}

@media (max-width: 440px) {
    .deposit-backround-big-planet {
        top: 94%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Deposit/deposit.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,QAAQ;IACZ;AACJ","sourcesContent":[".deposit-block {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    position: relative;\r\n    padding-bottom: 24%;\r\n    overflow: hidden;\r\n}\r\n\r\n.deposit-backround-big-planet {\r\n    position: absolute;\r\n    z-index: 1;\r\n    top: 68%;\r\n    width: 100vw;\r\n}\r\n\r\n@media (max-width: 1230px) {\r\n    .deposit-backround-big-planet {\r\n        top: 73%;\r\n    }\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n    .deposit-backround-big-planet {\r\n        top: 77%;\r\n    }\r\n}\r\n\r\n@media (max-width: 905px) {\r\n    .deposit-backround-big-planet {\r\n        top: 83%;\r\n    }\r\n}\r\n\r\n@media (max-width: 780px) {\r\n    .deposit-backround-big-planet {\r\n        top: 87%;\r\n    }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n    .deposit-block {\r\n        margin-top: 0;\r\n    }\r\n\r\n    .deposit-backround-big-planet {\r\n        top: 91%;\r\n    }\r\n}\r\n\r\n@media (max-width: 440px) {\r\n    .deposit-backround-big-planet {\r\n        top: 94%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
