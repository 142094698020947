import React from "react";
import './address.css';
import QR from '../../images/qr.png';
import { useNavigate } from "react-router-dom";
import { ReactComponent as ClockResetIcon } from "../../images/icons/Clock-reset.svg";
import { ReactComponent as BarsIcon } from "../../images/icons/2bars.svg";
import { ReactComponent as SecureIcon } from "../../images/icons/secure.svg";
import { ReactComponent as XIcon } from "../../images/icons/X-icon.svg";
import { ReactComponent as CopyIcon } from "../../images/icons/copy.svg";


const Address = () => {
    const navigate = useNavigate();
    return (
        <div className="address-block">
            <div className="short-info-container">
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Time remaining
                    </div>
                    <div className="status-value">28:55</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <BarsIcon />
                        Stage
                    </div>
                    <div className="status-value">Wait for Deposit</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Creation time
                    </div>
                    <div className="status-value">05.02.2024 20:06</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <ClockResetIcon />
                        Receiving time
                    </div>
                    <div className="status-value" style={{ 'color': '#6C6D7F' }}>Waiting</div>
                </div>
                <div className="row">
                    <div className="status-title">
                        <SecureIcon />
                        Volatility Protection Mode
                    </div>
                    <div className="status-value"><XIcon /></div>
                </div>
            </div>
            <div className="copy-address">
                <div className="copy-address-btn-container">
                    <div className="hint">
                        Send<b> 8,45442995 </b><b style={{ 'color': '#ffc000' }}> BUSD </b>to address
                    </div>
                    <div className="deposit-address">
                        0x807e7f3d5aqerqerrad4c9d64e15d7r5ra30d7t3757e7f3d473
                    </div>
                    <button className="copy-address" onClick={() => navigate(`/order/1/`)}>
                        <CopyIcon />
                        Copy address</button>
                </div>
                <div className="order-id">
                    Order ID: EXC240A1
                    <CopyIcon />
                </div>
            </div>
            <img src={QR} alt="qr" />
        </div>
    )
};

export default Address;
