import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiService } from "../../services/apiService"



const initialState = {
    id: 1,
    currency: {
        name: '',
        network: {
            name: '',
        }
    },
    depositStage: 'stage 1',
    paymentsAdress: null,
    amountUSDT: null,
    amoutBase: null,
    status: 'loading',
    authStage: 'login',
    isLoading: true,
    isError: false,
    error: null,
}


const getOrder = createAsyncThunk(
    'cosmoSlice/getOrder',
    async (id, thunkAPI) => {
        try {
            const { data } = await apiService.getOrder(id);
            console.log(data);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

const cosmoSlice = createSlice({
    name: 'cosmoSlice',
    initialState,
    reducers: {
        cancelError: (state) => {
            state.isError = false
        },
        setAuthstage: (state, action) => {
            state.authStage = action.payload
        },
        setStage: (state, action) => {
            state.depositStage = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getOrder.fulfilled, (state, action) => {
                state.id = action.payload.id;
                state.currency = action.payload.currency;
                state.paymentsAdress = action.payload.payments_adress;
                state.amountUSDT = action.payload.amount_usdt;
                state.amoutBase = action.payload.amount_base;
                state.status = action.payload.status;
                state.isLoading = false
            })
            .addCase(getOrder.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrder.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
                state.status = '';
                state.isError = true;
            })
    }
})

const { reducer: cosmoReducer, actions } = cosmoSlice;

const cosmoAction = {
    ...actions,
    getOrder
}

export {
    cosmoReducer,
    cosmoAction
}