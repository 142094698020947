import React from "react";
import './deposit.css';
import Info from "../../Components/Info/Info";
import Address from "../../Components/Address/Addess";
import Progress from "../../Components/Progress/Progress";
import BigPlanet from "../../images/BigPlanet.png";
import { Outlet } from "react-router-dom";

const Deposit = () => {
    return (
        <section className="deposit-block">
            <Info />
            <Address />
            <Outlet />
            <Progress />
            <img src={BigPlanet} alt="big planet" className="deposit-backround-big-planet" />
        </section>
    )
};

export default Deposit;
