import React, { useState } from "react";
import './registration.css';
import { ReactComponent as HideIcon } from "../../images/icons/hide-icon.svg";
import { ReactComponent as ShowIcon } from "../../images/icons/show-icon.svg";
import { useDispatch } from "react-redux";
import { cosmoAction } from "../../redux/slices/cosmoSlice";

const Registration = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setshowPasswordRepeat] = useState(false);
    const dispatch = useDispatch();
    return (
        <div className="registration-container">
            <form className="registration-content">
                <div className="login-or-register">
                    <div className="main-text">Registration</div>
                    <div className="or">or</div>
                    <div className="secondary-text" onClick={() => dispatch(cosmoAction.setAuthstage('login'))}>Login</div>
                </div>
                <label className="field" htmlFor="email">
                    <div className="hint">Enter your Email</div>
                    <div className="input-box">
                        <input id="email" name="email" type="email" placeholder="example@mail.com" autoComplete="off" />
                    </div>
                </label>
                <div className="field">
                    <div className="hint">Enter your Password</div>
                    <label className="input-box" htmlFor="password">
                        <input id="password" type={showPassword ? "text" : "password"} placeholder="********" />
                        <div className="hide-icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <ShowIcon /> : <HideIcon />}
                        </div>
                    </label>
                </div>
                <div className="field">
                    <div className="hint">Repeat password</div>
                    <label className="input-box" htmlFor="repeatPassword">
                        <input id="repeatPassword" type={showPasswordRepeat ? "text" : "password"} placeholder="423493..." />
                        <div className="hide-icon" onClick={() => setshowPasswordRepeat(!showPasswordRepeat)}>
                            {showPasswordRepeat ? <ShowIcon /> : <HideIcon />}
                        </div>
                    </label>
                </div>
                <div className="recovery-n-forget">
                    <div className="recovery" onClick={() => dispatch(cosmoAction.setAuthstage('recovery'))}>Recovery via Telegram API</div>
                </div>
                <div className="login-btn">Sign up</div>
            </form>
        </div>
    )
};

export default Registration;
