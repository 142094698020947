// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waiting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: 'Poppins';
    padding: 2.9% 10.9%;
    gap: 4.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Waiting/waiting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".waiting-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    color: white;\r\n    font-family: 'Poppins';\r\n    padding: 2.9% 10.9%;\r\n    gap: 4.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
