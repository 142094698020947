import React from "react";
import './exchangingConfirmation.css';
import { ReactComponent as ClockIcon } from "../../images/icons/Clock.svg";
import { ReactComponent as CopyIcon } from "../../images/icons/copy.svg";
import { ReactComponent as BarsIcon } from "../../images/icons/2bars.svg";
import { ReactComponent as ClockResetIcon } from "../../images/icons/Clock-reset.svg";


const ExchangingConfirmation = () => {
    return (
        <div className="exchanging-confirmation-block">
            <div className="exchanging-container">
                <div className="text-box">
                    <div className="title">Thank You
                        <div className="progress-counter-905px">
                            <div className="icon"><ClockIcon /></div>
                            <div className="counter-text">Exchanging</div>
                        </div>
                    </div>
                    <div className="sub-title">We received your Deposit, Waiting for Network Confirmations</div>
                </div>
                <div className="progress-counter">
                    <div className="icon"><ClockIcon /></div>
                    <div className="counter-text">Exchanging</div>
                </div>
            </div>
            <section className="short-info">
                <h2>About received transaction</h2>
                <div className="info-box">
                    <div className="address">
                        <div className="status-name">
                            <BarsIcon />
                            TxID
                        </div>
                        <div className="status-value">
                            <div className="address-text">0x807e7f3d5aqe15d7erre15d7d4c9d64e15d730d73754d7d4c9d64e15d73073</div>
                            <CopyIcon />
                        </div>
                    </div>
                    <div className="other-info">
                        <div className="row">
                            <div className="status-box">
                                <div className="status-name">
                                    <BarsIcon />
                                    Time of Receipt
                                </div>
                                <div className="status-value">05.02.2024 20:06</div>
                            </div>
                            <div className="status-box">
                                <div className="status-name">
                                    <ClockResetIcon />
                                    Time of Inclusion in Block
                                </div>
                                <div className="status-value">05.02.2024 20:06</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="status-box">
                                <div className="status-name">
                                    <BarsIcon />
                                    Amount
                                </div>
                                <div className="status-value">8,4544</div>
                            </div>
                            <div className="status-box">
                                <div className="status-name">
                                    <ClockResetIcon />
                                    Fee
                                </div>
                                <div className="status-value">0,0016</div>
                            </div>
                        </div>
                    </div>
                    <div className="confirmations">
                        <div className="status-name">
                            <ClockResetIcon />
                            Confirmations
                        </div>
                        <div className="status-value">4 of 4</div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ExchangingConfirmation;
